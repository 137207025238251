
/*--------------------------------------------------
	Ajax Page Transition
---------------------------------------------------*/
	
	main{
		overflow:hidden
	}
	main .cd-main-content>div{
		height:100%;
		min-height: 100vh;
	}
	main .cd-main-content{
		width:100%;
	}
	main .cd-main-content.cd-index>div{
		vertical-align:middle
	}
	main .cd-main-content.cd-index::after{
		background-color:rgba(64,137,166,0);
		background-image:-webkit-linear-gradient(bottom,#4089a6,rgba(64,137,166,0));
		background-image:linear-gradient(to top,#4089a6,rgba(64,137,166,0))
	}
	main .cd-main-content.cd-index::before{
		background-color:rgba(64,137,166,0);
		background-image:-webkit-linear-gradient(top,#4089a6,rgba(64,137,166,0));
		background-image:linear-gradient(to bottom,#4089a6,rgba(64,137,166,0))
	}
	main .cd-main-content.cd-about{
		background-color:#fff
	}
	main .cd-main-content.cd-about>div{
		padding-top:50px
	}
	main .cd-main-content.cd-about::after{
		background-color:rgba(40,48,64,0);
		background-image:-webkit-linear-gradient(bottom,#283040,rgba(40,48,64,0));
		background-image:linear-gradient(to top,#283040,rgba(40,48,64,0))
	}
	main .cd-main-content.cd-about::before{
		background-color:rgba(40,48,64,0);
		background-image:-webkit-linear-gradient(top,#283040,rgba(40,48,64,0));
		background-image:linear-gradient(to bottom,#283040,rgba(40,48,64,0))
	}
	.cd-cover-layer,.cd-cover-layer.invisible{
		background-color:transparent
	}
	.cd-cover-layer{
		position:fixed;
		z-index:5000;
		left:50%;
		top:50%;
		bottom:auto;
		right:auto;
		-webkit-transform:translateX(-50%) translateY(-50%);
		-moz-transform:translateX(-50%) translateY(-50%);
		-ms-transform:translateX(-50%) translateY(-50%);
		-o-transform:translateX(-50%) translateY(-50%);
		transform:translateX(-50%) translateY(-50%);
		height:101%;
		width:101%;
		opacity:0;
		visibility:hidden;
		-webkit-transition:opacity .4s .4s,visibility 0s .4s;
		-moz-transition:opacity .4s .4s,visibility 0s .4s;
		transition:opacity .6s 0s,visibility 0s .4s
	}
	.page-is-changing .cd-cover-layer{
		opacity:1;
		visibility:visible;
		-webkit-transition:opacity .3s 0s,visibility 0s 0s;
		-moz-transition:opacity .3s 0s,visibility 0s 0s;
		transition:opacity .3s .3s,visibility 0s 0s
	}
 

/*--------------------------------------------------
	Flex Nav
---------------------------------------------------*/	

	.flexnav,.flexnav li ul{
		width:100%;
		margin:0
	}
	.flexnav,.flexnav li{
		margin:0
	}
	.flexnav li,.flexnav li ul li{
		font-size:100%
	}
	.flexnav .touch-button:hover,.menu-button{
		cursor:pointer
	}
	.flexnav{
		-webkit-transition:none;
		-moz-transition:none;
		-ms-transition:none;
		transition:none;
		-webkit-transform-style:preserve-3d;
		overflow:hidden;
		max-height:0
	}
	.flexnav.opacity{
		opacity:0
	}
	.flexnav.flexnav-show{
		max-height:2000px;
		opacity:1;
		-webkit-transition:all .5s ease-in-out;
		-moz-transition:all .5s ease-in-out;
		-ms-transition:all .5s ease-in-out;
		transition:all .5s ease-in-out
	}
	.flexnav.one-page{
		position:fixed;
		top:50px;
		right:5%;
		max-width:200px
	}
	.flexnav li,.flexnav li a,.flexnav li ul li{
		position:relative;
		overflow:hidden
	}
	.flexnav li a{
		background:#fff;
		border-bottom:1px solid rgba(0,0,0,.05);
		color:#222;
		display:block;
		padding:20px 0;
		z-index:2
	}
	.oldie .flexnav li ul.open ul.open,.oldie .flexnav ul li:hover ul{
		margin-left:100%;
		top:0
	}
	.flexnav li ul.flexnav-show li,.oldie .flexnav,.oldie .flexnav li,.oldie .flexnav li a{
		overflow:visible
	}
	.flexnav li ul li a{
		background:#fff;
		display:block;
		padding:20px
	}
	.flexnav ul li ul li a{
		background:#bfbfbc
	}
	.flexnav ul li ul li ul li a{
		background:#cbcbc9
	}
	.flexnav .touch-button{
		background:rgba(0,0,0,0);
		display:inline-block;
		height:60px;
		position:absolute;
		right:0;
		text-align:right;
		top:0;
		width:60px;
		z-index:999
	}
	.flexnav .touch-button .navicon{
		position:relative;
		top:1.8em;
		font-size:12px;
		color:#666
	}
	.menu-button{
		background:0 0;
		border-bottom:medium none;
		box-sizing:border-box;
		color:#222;
		display:block;
		font-size:14px;
		font-weight:400;
		height:60px;
		line-height:60px;
		padding:0 50px;
		position:relative;
		text-align:right;
		text-transform:uppercase;
		z-index:0
	}
	.no-button .menu-button{
		padding-right:0
	}
	.menu-button.one-page{
		position:fixed;
		top:0;
		right:5%;
		padding-right:45px
	}
	.menu-button .touch-button{
		background:0 0;
		display:none;
		position:absolute;
		z-index:999;
		top:0;
		right:0;
		width:50px;
		height:50px;
		text-align:center
	}
	.menu-button .touch-button .navicon{
		font-size:16px;
		position:relative;
		top:1em;
		color:#666
	}
	@media all and (min-width:1025px){
		.flexnav li a,.flexnav li ul li a{
			border-bottom:none
		}
		.flexnav,.flexnav li{
			overflow:visible
		}
		body.one-page{
			padding-top:70px
		}
		.flexnav.opacity{
			opacity:1
		}
		.flexnav.one-page{
			top:0;
			right:auto;
			max-width:1080px
		}
		.flexnav li{
			position:relative;
			list-style:none;
			float:left;
			display:block;
			background-color:#a6a6a2
		}
		.flexnav li a{
			border-left:1px solid #acaca1
		}
		.flexnav li>ul{
			position:absolute;
			top:auto;
			left:0
		}
		.flexnav li ul li>ul,.flexnav li ul.open ul.open{
			margin-left:100%;
			top:0
		}
		.flexnav li>ul li{
			width:100%
		}
		.flexnav li ul.open{
			display:block;
			opacity:1;
			visibility:visible;
			z-index:1
		}
		.flexnav li ul.open li{
			overflow:visible;
			max-height:100px
		}
		.menu-button{
			display:none
		}
	}
	.oldie body.one-page{
		padding-top:70px
	}
	.oldie .flexnav.one-page{
		top:0;
		right:auto;
		max-width:1080px
	}
	.oldie .flexnav li{
		position:relative;
		list-style:none;
		float:left;
		display:block;
		background-color:#a6a6a2;
		width:20%;
		min-height:50px
	}
	.oldie .flexnav li:hover>ul{
		display:block;
		width:100%;
		overflow:visible
	}
	.oldie .flexnav li:hover>ul li{
		width:100%;
		float:none
	}
	.oldie .flexnav li a{
		border-left:1px solid #acaca1;
		border-bottom:none
	}
	.oldie .flexnav li>ul{
		background:#acaca1;
		position:absolute;
		top:auto;
		left:0;
		display:none;
		z-index:1;
		overflow:visible
	}
	.oldie .flexnav li ul.open,.oldie .flexnav li ul.open ul.open{
		display:block;
		width:100%;
		overflow:visible
	}
	.oldie .flexnav li ul li ul{
		top:0
	}
	.oldie .flexnav li ul li a{
		border-bottom:none
	}
	.oldie .flexnav li ul.open li{
		width:100%
	}
	.oldie .menu-button{
		display:none
	}
	.oldie.ie7 .flexnav li{
		width:19.9%
	}
	
/*--------------------------------------------------
	Tool Tips
---------------------------------------------------*/	

	[data-tooltip]{
		position:relative
	}
	[data-tooltip]::after,[data-tooltip]::before{
		position:absolute;
		display:block;
		opacity:0;
		pointer-events:none;
		-webkit-transition:all .2s ease-in-out;
		transition:all .2s ease-in-out;
		line-height:12px
	}
	[data-tooltip]::before{
		content:attr(data-tooltip);
		font-size:12px;
		font-weight:500;
		font-family:Poppins,sans-serif;
		color:#000;
		padding:5px;
		border-radius:5px;
		white-space:nowrap;
		text-decoration:none;
		text-align:left
	}
	.light-content [data-tooltip]::before{
		color:#fff
	}
	[data-tooltip]::after{
		width:0;
		height:0;
		border:6px solid transparent;
		content:''
	}
	[data-tooltip]:hover::after,[data-tooltip]:hover::before{
		opacity:1
	}
	[data-tooltip][data-placement=left]::before{
		top:32%;
		right:100%;
		margin-right:35px
	}
	[data-tooltip][data-placement=left]:hover::before{
		margin-right:-2px;
		-webkit-transition:all .3s ease-out .5s;
		transition:all .3s ease-out .5s
	}
	[data-tooltip][data-placement=top]::before{
		top:16%;
		right:100%;
		margin-right:0;
		width:200px;
		height:16px;
		transform:translateX(147px) translateY(-170px) rotate(-90deg)
	}
	[data-tooltip][data-placement=top]:hover::before{
		transform:translateX(147px) translateY(-140px) rotate(-90deg);
		-webkit-transition:all .3s ease-out .5s;
		transition:all .3s ease-out .2s
	}
	[data-tooltip][data-placement=right]::before{
		top:32%;
		left:100%;
		margin-left:35px
	}
	[data-tooltip][data-placement=right]:hover::before{
		margin-left:-2px;
		-webkit-transition:all .3s ease-out .5s;
		transition:all .3s ease-out .5s
	}
	@media only screen and (max-width:479px){
		[data-tooltip]:hover::after,[data-tooltip]:hover::before{
			opacity:0
		}
	}


/*--------------------------------------------------
	Social Share
---------------------------------------------------*/	

	.jssocials-shares{
		margin:.2em 0
	}
	.jssocials-shares *{
		box-sizing:border-box
	}
	.jssocials-share{
		display:inline-block;
		vertical-align:middle;
		margin:0;
	}
	.jssocials-share:last-child{
		margin-right:0
	}
	.jssocials-share-logo{
		width:1em;
		vertical-align:middle;
		font-size: 1em;
	}
	img.jssocials-share-logo{
		width:auto;
		height:1em
	}
	.jssocials-share-link{
		display:block;
		text-align:center;
		text-decoration:none;
		width: 60px;
		height: 60px;
		line-height: 60px;
	}
	.jssocials-share-link.jssocials-share-link-count{
		padding-top:.2em
	}
	.jssocials-share-link.jssocials-share-link-count .jssocials-share-count{
		display:block;
		font-size:.6em;
		margin:0 -.5em -.8em
	}
	.jssocials-share-link.jssocials-share-no-count{
		padding-top:.5em
	}
	.jssocials-share-link.jssocials-share-no-count .jssocials-share-count{
		height:1em
	}
	.jssocials-share-label{
		padding-left:.3em;
		vertical-align:middle
	}
	.jssocials-share-count-box{
		display:inline-block;
		height:1.5em;
		padding:0 .3em;
		line-height:1;
		vertical-align:middle;
		cursor:default
	}
	.jssocials-share-count-box.jssocials-share-no-count{
		display:none
	}
	.jssocials-share-count{
		line-height:1.5em;
		vertical-align:middle
	}


/*--------------------------------------------------
	Justified Gallery
---------------------------------------------------*/	

	.justified-gallery{
		width:100%;
		position:relative;
		overflow:hidden
	}
	.justified-gallery>a,.justified-gallery>div,.justified-gallery>figure{
		position:absolute;
		display:inline-block;
		overflow:hidden;
		filter:"alpha(opacity=10)";
		opacity:.1;
		margin:0;
		padding:0
	}
	.justified-gallery>a>a>img,.justified-gallery>a>img,.justified-gallery>div>a>img,.justified-gallery>div>img,.justified-gallery>figure>a>img,.justified-gallery>figure>img{
		position:relative;
		top:50%;
		left:50%;
		margin:0;
		margin-top:0!important;
		padding:0;
		border:none;
		filter:"alpha(opacity=0)";
		opacity:0
	}
	.justified-gallery>a>.caption,.justified-gallery>div>.caption,.justified-gallery>figure>.caption{
		display:none;
		position:absolute;
		bottom:0;
		padding:5px;
		background-color:#000;
		left:0;
		right:0;
		margin:0;
		color:#fff;
		font-size:12px;
		font-weight:300;
		font-family:sans-serif
	}
	.justified-gallery>.entry-visible{
		filter:"alpha(opacity=100)";
		opacity:1;
		background:0 0
	}
	.justified-gallery>.entry-visible>a>img,.justified-gallery>.entry-visible>img{
		filter:"alpha(opacity=100)";
		opacity:1;
		-webkit-transition:opacity .5s ease-in;
		-moz-transition:opacity .5s ease-in;
		-o-transition:opacity .5s ease-in;
		transition:opacity .5s ease-in
	}
	.justified-gallery>.jg-filtered{
		display:none
	}
	.justified-gallery>.spinner{
		position:absolute;
		bottom:0;
		margin-left:-24px;
		padding:10px 0;
		left:50%;
		filter:"alpha(opacity=100)";
		opacity:1;
		overflow:initial
	}
	.justified-gallery>.spinner>span{
		display:inline-block;
		filter:"alpha(opacity=0)";
		opacity:0;
		width:8px;
		height:8px;
		margin:0 4px;
		background-color:#fff;
		border-radius:6px
	}
	
/*--------------------------------------------------
	ClaPat Popup
---------------------------------------------------*/	
	
	.clapat-img-popup, .clapat-video-popup {
		position:fixed;
		height:100%;
		width:100%;
		z-index:10000;
		left:0;
		top:0;
		right:0;
		bottom:0;
		display:flex;
		justify-content: center;	
  		align-items: center;
	}
	
	.clapat-img-popup-viewport {
		position: absolute;
		display: flex;
		box-sizing: border-box;
		padding: 80px 40px;
		margin: 0 auto;
		text-align: left;
		z-index: 100;
		height: auto;
		width: auto;
		align-items: center;
		justify-content: center;
		pointer-events: none;
	}
	
	.clapat-video-popup-viewport {
		position: absolute;
		display: flex;
		box-sizing: border-box;
		padding: 0;
		margin: 0 auto;
		text-align: left;
		z-index: 100;
		height: auto;
		width: calc(100% - 80px);
		max-width: 900px;
		align-items: center;
		justify-content: center;
		pointer-events: none;
	}
	
	.clapat-video-popup-viewport::before {
		float: left;
		padding-top: 56.25%;
		content: "";
	}
	
	.clapat-img-popup-preloader, .clapat-video-popup-preloader {
		display: block;
		position: absolute;
		margin: 0 auto;
		width: 80px;
		height: 80px;
	}
	
	.clapat-img-popup-preloader div, .clapat-video-popup-preloader div {
		position: absolute;
		top: 33px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: #fff;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}
	
	.clapat-img-popup-preloader div:nth-child(1), .clapat-video-popup-preloader div:nth-child(1) {
		left: 8px;
		animation: clapat-img-popup-preloader1 0.6s infinite;
	}
	
	.clapat-img-popup-preloader div:nth-child(2), .clapat-video-popup-preloader div:nth-child(2) {
		left: 8px;
		animation: clapat-img-popup-preloader2 0.6s infinite;
	}
	
	.clapat-img-popup-preloader div:nth-child(3), .clapat-video-popup-preloader div:nth-child(3) {
		left: 32px;
		animation: clapat-img-popup-preloader2 0.6s infinite;
	}
	
	.clapat-img-popup-preloader div:nth-child(4), .clapat-video-popup-preloader div:nth-child(4) {
		left: 56px;
		animation: clapat-img-popup-preloader3 0.6s infinite;
	}
	
	@keyframes clapat-img-popup-preloader1 {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}
	
	@keyframes clapat-img-popup-preloader3 {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}
	
	@keyframes clapat-img-popup-preloader2 {
		0% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(24px, 0);
		}
	}

	
	.clapat-img-popup img {
		position: relative;
		max-width: 100%;
		max-height: calc(100vh - 160px);
		width: auto;
		height: auto;
		margin: 0 auto;
		z-index: 10;
		pointer-events: initial;
	}
	
	.clapat-video-popup iframe {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		pointer-events: initial;
	}
	
	.rounded-borders .clapat-img-popup img {
		border-radius:8px;
	}
	
	.clapat-img-popup-bg-close, .clapat-video-popup-bg-close {
		position:absolute;
		display:block;
		height:100%;
		width:100%;
		left:0;
		top:0;
		right:0;
		bottom:0;
	}
	
	.clapat-img-popup-close {
		position:absolute;
		width:40px;
		height:40px;
		right:20px;
		top:60px;
		background-color:rgba(0,0,0,1);
		color:#fff;
		opacity:0;
		border-radius:20px;
		z-index: 100;
		cursor:pointer;
		pointer-events: initial;
		-webkit-transition: background-color 0.2s ease-in-out;
		transition: background-color 0.2s ease-in-out;
	}
	
	.clapat-video-popup-close {
		position:absolute;
		width:40px;
		height:40px;
		right:-20px;
		top:-20px;
		background-color:rgba(0,0,0,1);
		color:#fff;
		opacity:0;
		border-radius:20px;
		z-index: 100;
		cursor:pointer;
		pointer-events: initial;
		-webkit-transition: background-color 0.2s ease-in-out;
		transition: background-color 0.2s ease-in-out;
	}
	
	.clapat-img-popup-close:hover, .clapat-video-popup-close:hover {
		background-color:rgba(0,0,0,1);
	}
	
	.clapat-img-popup-close::after, .clapat-video-popup-close::after {
		font: var(--fa-font-solid);
		content: "\f00d";
		font-size: 16px;
		line-height: 40px;
		text-align: center;
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
	}
	
	.clapat-img-popup-prev, .clapat-video-popup-prev {
		position:absolute;
		width: 26px;
		height: 50px;
		left:60px;
		background-color:rgba(0,0,0,1);
		color:#fff;
		opacity:0;
		border-radius:4px;
		z-index: 100;
		cursor:pointer;
		-webkit-transition: background-color 0.2s ease-in-out;
		transition: background-color 0.2s ease-in-out;
	}
	
	.clapat-img-popup-prev:hover, .clapat-video-popup-prev:hover {
		background-color:rgba(0,0,0,1);
	}
	
	.clapat-img-popup-prev::after, .clapat-video-popup-prev::after {
		font: var(--fa-font-solid);
		content: "\f104";
		font-size: 16px;
		line-height: 50px;
		text-align: center;
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
	}
	
	.clapat-img-popup-next, .clapat-video-popup-next {
		position:absolute;
		width: 26px;
		height: 50px;
		right:60px;
		background-color:rgba(0,0,0,1);
		color:#fff;
		opacity:0;
		border-radius:4px;
		z-index: 100;
		cursor:pointer;
		-webkit-transition: background-color 0.2s ease-in-out;
		transition: background-color 0.2s ease-in-out;
	}
	
	.clapat-img-popup-next:hover, .clapat-video-popup-next:hover {
		background-color:rgba(0,0,0,1);
	}
	
	.clapat-img-popup-next::after, .clapat-video-popup-next::after {
		font: var(--fa-font-solid);
		content: "\f105";
		font-size: 16px;
		line-height: 50px;
		text-align: center;
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
	}
	
	
@media only screen and (max-width: 1466px) {
	
		
}	
	
	
@media only screen and (max-width: 1024px) {	


}
	
	
@media only screen and (max-width: 767px) {


}

@media only screen and (max-width: 479px) {
	
	.clapat-img-popup-viewport {
		padding: 60px 23px;
	}
	
	.clapat-video-popup-viewport {
  		width: calc(100% - 60px);
	}
	
	.clapat-img-popup img {
		max-height: calc(100vh - 120px);
	}
	
	.clapat-img-popup-close {
		right: 3px;
    	top: 40px
	}
	
	.clapat-img-popup-prev {
		left:10px;
	}
	
	.clapat-img-popup-next {
		right:10px;
	}
	
}	
